<template>
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col" style="z-index: unset">
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 border-r">
      <div class="flex justify-center h-16 shrink-0 items-center">
        <img class="h-16 w-auto invert" src="/logo.png">
      </div>
      <nav class="flex flex-1 flex-col">
        <ul class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul class="-mx-2 space-y-1">
              <li v-for="menu_item in MENU_ITEMS">
                <nuxt-link
                    :class="$route.path === menu_item.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50'"
                    :to="menu_item.href"
                    class="group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6">
                  <icon :name="menu_item.icon" size="24"/>

                  {{ menu_item.label }}
                </nuxt-link>
              </li>
            </ul>
          </li>

          <li>
            <feedback-note title="Save time and increase engagement" :bold="true"/>

            <ul class="-mx-2">
              <li v-for="extra_menu_item in EXTRA_MENU_ITEMS">
                <nuxt-link
                    :class="$route.path === extra_menu_item.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50'"
                    :to="extra_menu_item.href"
                    class="group flex gap-x-3 rounded-md p-2 text-sm leading-6">
                  <icon :name="extra_menu_item.icon" size="24"/>

                  {{ extra_menu_item.label }}
                </nuxt-link>
              </li>
            </ul>
          </li>

          <nuxt-link class="mt-auto" to="/settings/billing">
            <div class="flex flex-col space-y-3 bg-gray-100 p-4 rounded-md">
              <h3 class="label-sm font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                <a href="#">
                  The boss lost his mind 🤯
                </a>
              </h3>

              <p class="text-sm leading-6 text-gray-600">
                👉 He is offering one of the paid plans for free for a limited time. <span class="link">Grab it now!</span>
              </p>
            </div>
          </nuxt-link>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import {
  CALENDAR,
  CONNECT,
  CREATE_POST,
  POSTS,
  HASHTAG_LISTS,
  SIGNATURES,
  TEMPLATES,
  WATERMARKS
} from '~/helpers/navigation-items.js'

const MENU_ITEMS = ref([CREATE_POST, POSTS, CALENDAR, CONNECT])

const EXTRA_MENU_ITEMS = ref([WATERMARKS, TEMPLATES, SIGNATURES, HASHTAG_LISTS])
</script>
